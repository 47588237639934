$offWhite: #fdf9ef;
$maxHeaderSize: 120px;
$maxDetailSize: 18px;

#home {
  margin: 0;
  height: 100%;
  min-height: 100%;

  &.red {
    background-color: #ed6557;
    // background-color: #dc382f;
  }

  &.blue {
    background-color: rgb(47, 57, 83);
  }

  &.tan {
    background-color: rgb(233, 207, 135);

    .emphasis {
      color: #3ba4eb;
    }
  }

  &.gunmetal {
    background-color: rgb(28, 29, 32);

    .detail {
      color: #b8ccdd;
    }
  }
}

#stuff {
  position: absolute;
  top: 0;
  left: 40px;
  top: 40px;
  z-index: 2;
}

.paper-boy {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.big-header {
  font-family: 'Rubik Mono One', sans-serif;
  font-weight: normal;
  line-height: 1em;
  letter-spacing: -0.05em;

  margin: 0;
  color: $offWhite;
  font-size: unquote("min(#{$maxHeaderSize}, 12vw)");
}

.detail {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: black;
  font-size: unquote("min(#{$maxDetailSize}, 1.8vw)");

  .emphasis {
    color: #ebe42c;
  }
}


#goof-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.life-is-a-joke {
  .big-header {
    font-family: 'Sniglet', cursive;
    letter-spacing: 0em;
  }
}
