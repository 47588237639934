html, body, #root, .App {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

body {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sniglet:wght@800&display=swap');
