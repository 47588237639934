@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sniglet:wght@800&display=swap);
html, body, #root, .App {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

body {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#home{margin:0;height:100%;min-height:100%}#home.red{background-color:#ed6557}#home.blue{background-color:#2f3953}#home.tan{background-color:#e9cf87}#home.tan .emphasis{color:#3ba4eb}#home.gunmetal{background-color:#1c1d20}#home.gunmetal .detail{color:#b8ccdd}#stuff{position:absolute;top:0;left:40px;top:40px;z-index:2}.paper-boy{position:absolute;top:0;width:100%;height:100%;z-index:1}.big-header{font-family:'Rubik Mono One', sans-serif;font-weight:normal;line-height:1em;letter-spacing:-0.05em;margin:0;color:#fdf9ef;font-size:min(120px, 12vw)}.detail{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:black;font-size:min(18px, 1.8vw)}.detail .emphasis{color:#ebe42c}#goof-button{position:absolute;top:0;right:0;cursor:pointer}.life-is-a-joke .big-header{font-family:'Sniglet', cursive;letter-spacing:0em}

